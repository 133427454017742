import { setupGlobals } from '../util/GlobalSetup.js';
setupGlobals();

import '../chunks/polyfills.js';
import '../chunks/webpack-public-path.js';

import { init as initHome } from '../controllers/home.js';
import { switchLocale } from '../i18n/home.i18n.js';
import '../chunks/shared-webcomponents.js';
import '../chunks/home-webcomponents.js';
import 'angular';
import '../angular_apps/home.js';
import '../../../../vendor/assets/javascripts/google-analytics-shim.js';

import { enableFlag } from '@adobe-fonts/site/lib/FeatureFlag.js';
import { getData } from '../util/script_data.js';
import { initI18n } from '../util/i18n_util.js';

// If the use model v2 flag is enabled, pass the flag on to the AFS codebase.
const flipperFlags = getData('flipper-flags');
if (flipperFlags && flipperFlags.enableUseModelV2) {
  enableFlag('EnableUseModelV2');
}

initI18n(switchLocale);
initHome();
